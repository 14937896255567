import routes from "@/constants/routes";
import getConfig from "next/config";
import { LandingNavLink } from "./types";

const { publicRuntimeConfig } = getConfig();

export const NowLinkItems: LandingNavLink[] = [
  { caption: "How it works", href: routes.howItWorks },
  { caption: "FAQs", href: routes.faqs },
  {
    caption: "About",
    items: [
      { caption: "Our story", href: routes.about },
      { caption: "Key workers", href: routes.keyWorkers },
      { caption: "Success stories", href: routes.customerReviews },
      { caption: "Careers", href: "mailto:careers@keyzy.com" },
      { caption: "Contact us", href: routes.contact },
    ],
    href: "",
  },
  {
    caption: "Neighbourhoods",
    items: [
      { caption: "Bermondsey", href: routes.neighbourhoods.bermondsey },
      { caption: "Canning Town", href: routes.neighbourhoods.canningTown },
      { caption: "Clapham", href: routes.neighbourhoods.clapham },
      { caption: "Croydon", href: routes.neighbourhoods.croydon },
      { caption: "Ealing", href: routes.neighbourhoods.ealing },
      { caption: "Tooting Broadway", href: routes.neighbourhoods.tootingBroadway },
      { caption: "Wandsworth", href: routes.neighbourhoods.wandsworth },

      { caption: "Tell us where you want to live", href: routes.registerInterest },
    ],
    href: "",
  },
  { caption: "Login", href: routes.dashboard.login },
];
