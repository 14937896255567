import routes from "@/constants/routes";
import { FooterLinks } from "./types";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const blogLink =
  publicRuntimeConfig?.NEXT_BLOG_PAGE === "1" ? routes.blog : "https://medium.com/@keyzy";

export const footerLinks: FooterLinks = [
  {
    name: "Service",
    items: [
      { name: "How it works", href: routes.howitworks },
      { name: "Calculator", href: `${routes.home}#calculator` },
      { name: "Key Workers", href: routes.keyWorkers },
    ],
  },
  {
    name: "Company",
    items: [
      { name: "About Keyzy", href: routes.about },
      { name: "Careers", href: "mailto:careers@keyzy.com" },
      { name: "Press", href: routes.press },
    ],
  },
  {
    name: "Intermediaries",
    items: [
      { name: "Benefits", href: `${routes.intermediaries}#why` },
      { name: "Partner with Keyzy", href: "https://intermediaries.keyzy.com/sign-up" },
      { name: "Portal Login", href: "https://intermediaries.keyzy.com/" },
    ],
  },
  {
    name: "Other",
    items: [
      { name: "Terms of Use", href: routes.termsOfUse },
      { name: "Privacy Policy", href: routes.privacy },
      { name: "Blog", href: blogLink },
    ],
  },
];
