import React from "react";
import Footer from "@/components/landing-old/Footer";
import Header from "@/components/landing-new/Header";
import { MainLayoutProps } from "./types";
import { useRouter } from "next/router";
import routes from "@/constants/routes";
import { useModalPopupContext } from "@/contexts/navigationContext/ModalPopupContext";

const MainLayout = ({ children, theme = "dark" }: MainLayoutProps): JSX.Element => {
  const { setShowModal } = useModalPopupContext();
  const router = useRouter();

  const landlordRoots = [
    routes.landlords.root,
    routes.landlords.aboutUs,
    routes.landlords.dealCalculator,
    routes.landlords.howItWorks,
    routes.landlords.articles,
  ];

  return (
    <>
      <div className={`flex flex-col items-center ${theme === "ivory" && "bg-landing-ivory"}`}>
        <Header theme={theme} setShowModal={setShowModal as any} />
        <main className="w-full">{children}</main>
        <Footer
          setShowModal={setShowModal as any}
          parentPage={landlordRoots.includes(router.pathname) ? "landlords" : ""}
        />
      </div>
    </>
  );
};

export default MainLayout;
